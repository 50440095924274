import { createGlobalStyle } from 'styled-components';

import NotoSansRegular from './NotoSans-Regular.ttf';
import NotoSansItalic from './NotoSans-Italic.ttf';
import NotoSansBold from './NotoSans-Bold.ttf';
import NotoSansBoldItalic from './NotoSans-BoldItalic.ttf';
import NotoSansLight from './NotoSans-Light.ttf';

import ObjektivMk2Regular from './ObjektivMk2-Regular.ttf';
import ObjektivMk2Italic from './ObjektivMk2-Italic.ttf';
// import ObjektivMk2Medium from './ObjektivMk2-Medium.ttf';
import ObjektivMk2Bold from './ObjektivMk2-Bold.ttf';
import ObjektivMk2BoldItalic from './ObjektivMk2-BoldItalic.ttf';
import ObjektivMk2XBold from './ObjektivMk2-XBold.ttf';
import ObjektivMk2XBoldItalic from './ObjektivMk2-XBoldItalic.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Noto Sans';
        src: local('Noto Sans'), url(${NotoSansLight}) format('truetype');
        font-weight: 300;
    }
    @font-face {
        font-family: 'Noto Sans';
        src: local('Noto Sans'), url(${NotoSansRegular}) format('truetype');
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: 'Noto Sans';
        src: local('Noto Sans'), url(${NotoSansItalic}) format('truetype');
        font-style: italic;
        font-weight: 400;
    }
    @font-face {
        font-family: 'Noto Sans';
        src: local('Noto Sans'), url(${NotoSansBold}) format('truetype');
        font-weight: 700;
    }
    @font-face {
        font-family: 'Noto Sans';
        src: local('Noto Sans'), url(${NotoSansBoldItalic}) format('truetype');
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: 'ObjektivMk2';
        src: local('ObjektivMk2'), url(${ObjektivMk2Regular}) format('truetype');
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: 'ObjektivMk2';
        src: local('ObjektivMk2'), url(${ObjektivMk2Italic}) format('truetype');
        font-style: italic;
        font-weight: 400;
    }
    @font-face {
        font-family: 'ObjektivMk2';
        src: local('ObjektivMk2'), url(${ObjektivMk2Bold}) format('truetype');
        font-weight: 600;
    }
    @font-face {
        font-family: 'ObjektivMk2';
        src: local('ObjektivMk2'), url(${ObjektivMk2BoldItalic}) format('truetype');
        font-weight: 600;
    }
    @font-face {
        font-family: 'ObjektivMk2';
        src: local('ObjektivMk2'), url(${ObjektivMk2XBold}) format('truetype');
        font-weight: 700;
    }
    @font-face {
        font-family: 'ObjektivMk2';
        src: local('ObjektivMk2'), url(${ObjektivMk2XBoldItalic}) format('truetype');
        font-weight: 700;
        font-style: italic;
    }
`;
