import * as React from 'react';
import GlobalFonts from './src/assets/fonts/Fonts';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <>
    <GlobalFonts />
    {element}
  </>
);
