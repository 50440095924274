exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-academy-tsx": () => import("./../../../src/pages/academy.tsx" /* webpackChunkName: "component---src-pages-academy-tsx" */),
  "component---src-pages-android-tsx": () => import("./../../../src/pages/android.tsx" /* webpackChunkName: "component---src-pages-android-tsx" */),
  "component---src-pages-app-design-tsx": () => import("./../../../src/pages/app-design.tsx" /* webpackChunkName: "component---src-pages-app-design-tsx" */),
  "component---src-pages-approach-tsx": () => import("./../../../src/pages/approach.tsx" /* webpackChunkName: "component---src-pages-approach-tsx" */),
  "component---src-pages-bizworksheet-thankyou-tsx": () => import("./../../../src/pages/bizworksheet-thankyou.tsx" /* webpackChunkName: "component---src-pages-bizworksheet-thankyou-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-draft-tsx": () => import("./../../../src/pages/draft.tsx" /* webpackChunkName: "component---src-pages-draft-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ios-tsx": () => import("./../../../src/pages/ios.tsx" /* webpackChunkName: "component---src-pages-ios-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */),
  "component---src-pages-work-imprint-tsx": () => import("./../../../src/pages/work/imprint.tsx" /* webpackChunkName: "component---src-pages-work-imprint-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-realtor-tsx": () => import("./../../../src/pages/work/realtor.tsx" /* webpackChunkName: "component---src-pages-work-realtor-tsx" */),
  "component---src-pages-work-tapping-solution-tsx": () => import("./../../../src/pages/work/tapping-solution.tsx" /* webpackChunkName: "component---src-pages-work-tapping-solution-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */)
}

